import React from 'react'
import {graphql} from 'gatsby'
import Layout from 'gatsby-theme-psg/src/components/Layout'
import Header from '../components/header/Header'
import Tickets from 'gatsby-theme-psg/src/components/Tickets/Eventix'
import Lead from '../components/header/Lead'

const TicketsPage = ({data}) => {
    const { markdownRemark: post } = data
    return (
        <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
            <Header>
                <Lead title={post.frontmatter.title} content={post.html} />
            </Header>
            <div className="my-5 pb-5">
                <div className="container">
                    <Tickets />
                </div>
            </div>
        </Layout>
    )
}

export default TicketsPage

export const query = graphql`
    query TicketsPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                description
            }
        }
    }
`
